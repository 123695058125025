import React from 'react';
import ReactDOM from 'react-dom';

import { Main } from './Main.js';
import { Bday } from './Bday.js';

import './default.scss';

if (window.location.pathname === '/tinytuna28') {
  ReactDOM.render(<Bday />, document.getElementById('root'));
} else {
  ReactDOM.render(<Main />, document.getElementById('root'));
}
