import React, { useState, useEffect, useContext } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { Confettiful } from './confetti.js';

import img0 from './assets/img0.png';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';
import img9 from './assets/img9.png';
import img10 from './assets/img10.png';

import giftIcon from './assets/gift.svg';
import giftIconBlue from './assets/gift-blue.svg';
import giftIconYellow from './assets/gift-yellow.svg';
import giftIconLime from './assets/gift-lime.svg';
import giftIconPeach from './assets/gift-peach.svg';
import giftIconGray from './assets/gift-gray.svg';

import hughspa1 from './assets/hughspa1.jpg';
import hughspa2 from './assets/hughspa2.jpg';
import inhomemassage from './assets/inhomemassage.jpg';

require('./confetti');

const SUBTEXTS = [
  'i love you endlessly, and it kills me that i can\'t physically celebrate with you today.',
  'if i were there i would kiss each of your cheeks 28 times.',
  'and 28 times more.'
]

const PIC_TICK = SUBTEXTS.length;

export function Bday(props) {
  const [ isIn, setIsIn ] = useState(false);
  const [ pass, setPass ] = useState('');
  const [ subtexts, setSubtexts ] = useState([]);
  const [ ticks, setTicks ] = useState(0);
  const [ seconds, setSeconds ] = useState(0);
  const [ showGifts, setShowGifts ] = useState(false);
  const [ firstOpened, setFristOpened ] = useState(false);
  const [ secondOpened, setSecondOpened ] = useState(false);

  useEffect(() => {
    const tickInterval = setInterval(() => {
      setSubtexts(currtexts => {
        if (currtexts.length < SUBTEXTS.length) {
          return SUBTEXTS.slice(0, currtexts.length + 1);
        }
        return currtexts;
      })
      setTicks(t => t + 1);
    }, 3000);

    const secondInterval = setInterval(() => {
      setSeconds(s => s + 1);
    }, 1000);

    return () => {
      clearInterval(tickInterval);
      clearInterval(secondInterval);
    };
  }, []);

  useEffect(() => {
    if (pass === 'clocktowercrossroads') {
      setIsIn(true);
      setSubtexts([]);
      setTicks(0);

      const bdayElem = document.querySelector(".Bday");
      if (bdayElem) {
        new Confettiful(bdayElem);
      }
    }
  }, [pass]);

  const renderFadeWrap = (content) => {
    return (
      <ReactCSSTransitionGroup
          transitionName="FadeAnim"
          transitionAppear={true}
          transitionAppearTimeout={400}
          transitionEnter={true}
          transitionEnterTimeout={400}
          transitionLeave={true}
          transitionLeaveTimeout={400}>
        {content}
      </ReactCSSTransitionGroup>
    );
  }

  const renderSubtexts = () => {
    return <div className="Bday-subtexts">
      {
        SUBTEXTS.map((st, ind) => {
          return <div className={(ticks > ind) ? `Bday-subtext Shown` : `Bday-subtext Hidden`} key={ind}>{st}</div>
        })
      }
    </div>
  }

  const renderMain = () => {
    return <div className="Bday-main">
      {renderSubtexts()}

      <div className={(ticks > PIC_TICK) ? `Bday-imageSection Shown` : `Bday-imageSection Hidden`}>
        <h2 className={(ticks > PIC_TICK) ? `Bday-imageHeading Shown` : `Bday-imageHeading Hidden`}>
          here are some pictures that have brought me joy
          <br />
          during our time far apart
        </h2>

        <div className="Bday-images">
          <img className={(ticks > PIC_TICK + 1) ? `Bday-image Shown` : `Bday-image Hidden`} src={img0} />
          <img className={(ticks > PIC_TICK + 2) ? `Bday-image Shown` : `Bday-image Hidden`} src={img1} />
          <img className={(ticks > PIC_TICK + 3) ? `Bday-image Shown` : `Bday-image Hidden`} src={img2} />
          <img className={(ticks > PIC_TICK + 4) ? `Bday-image Shown` : `Bday-image Hidden`} src={img3} />
          <img className={(ticks > PIC_TICK + 5) ? `Bday-image Shown` : `Bday-image Hidden`} src={img4} />
          <img className={(ticks > PIC_TICK + 6) ? `Bday-image Shown` : `Bday-image Hidden`} src={img5} />
          <img className={(ticks > PIC_TICK + 7) ? `Bday-image Shown` : `Bday-image Hidden`} src={img6} />
          <img className={(ticks > PIC_TICK + 8) ? `Bday-image Shown` : `Bday-image Hidden`} src={img7} />
          <img className={(ticks > PIC_TICK + 9) ? `Bday-image Shown` : `Bday-image Hidden`} src={img8} />
          <img className={(ticks > PIC_TICK + 10) ? `Bday-image Shown` : `Bday-image Hidden`} src={img9} />
          <img className={(ticks > PIC_TICK + 11) ? `Bday-image Shown` : `Bday-image Hidden`} src={img10} />
        </div>
      </div>

      <div className={ticks > PIC_TICK + 13 ? `Bday-showGiftsWrap Shown` : `Bday-showGiftsWrap Hidden`}>
        <div className="Bday-showGiftsText">
          oh, did you want a gift or something?
        </div>

        <button className="Bday-showGiftsButton" onClick={() => setShowGifts(true)}>
          um, yeah!
        </button>
      </div>
    </div>
  }
  //      <div className={(ticks > PIC_TICK + 13) ? `Bday-showGiftsWrap Shown` : `Bday-showGiftsWrap Hidden`}>


  const renderGifts = () => {
    const gift1 = firstOpened ? (
      <div className="Bday-gift Bday-gift--1">
        <div className="Bday-openedGift">
          <div className="Bday-giftContent">
            <h2 className="Bday-giftTitle">
              a day at a korean bathouse
            </h2>
            <div className="Bday-giftText">
              this semester has been super stressful, and you've earned some deep relaxation time.
            </div>
            <div className="Bday-giftText">
              but you're still in the thick of it, so i've loaded some money on a card to <a href="https://hughspa.com/" target="_blank">hugh spa</a>, a beautiful jimjilbang bathouse in ktown, so you can go after the semester or whenever you'd like.
            </div>
            <div className="Bday-giftText">
              it's enough for two treatments – a scrub and a facial. they also get you admission to the whole bathhouse so you could make two days out of it if you want!
            </div>
          </div>
          <div className="Bday-giftImages">
            <img className="Bday-giftImage" src={hughspa1} />
            <img className="Bday-giftImage" src={hughspa2} />
          </div>
        </div>
      </div>
    ) : (
      <div className="Bday-gift Bday-gift--1">
        <button className={`Bday-giftButton Bday-giftButton--1 Bday-giftButton--${seconds % 2 == 0 ? 'left' : 'right'}`}
                onClick={() => setFristOpened(true)}>
          <div className="Bday-giftIconWrap">
            <img className="Bday-giftIcon" src={giftIconGray} />
            <img className="Bday-giftIcon" src={giftIconPeach} />
            <img className="Bday-giftIcon" src={giftIconLime} />
            <img className="Bday-giftIcon" src={giftIconYellow} />
            <img className="Bday-giftIcon" src={giftIconBlue} />
            <img className="Bday-giftIcon" src={giftIcon} />
          </div>
        </button>
      </div>
    );

    const gift2 = secondOpened ? (
      <div className="Bday-gift Bday-gift--2">
        <div className="Bday-openedGift">
          <div className="Bday-giftImages">
            <img className="Bday-giftImage" src={inhomemassage} />
          </div>
          <div className="Bday-giftContent">
            <h2 className="Bday-giftTitle">
              a masseuse to your door
            </h2>
            <div className="Bday-giftText">
              something in the more immediate future would be nice as well.
            </div>
            <div className="Bday-giftText">
              so you name the date and time, and I will confirm your appointment with an at home masseuse.
            </div>
            <div className="Bday-giftText">
              you can fit it into your school schedule whenever you have an hour to spare – no need to drive anywhere!
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="Bday-gift Bday-gift--2">
        <button className={`Bday-giftButton Bday-giftButton--2 Bday-giftButton--${seconds % 2 == 1 ? 'left' : 'right'}`}
                onClick={() => setSecondOpened(true)}>
          <div className="Bday-giftIconWrap">
            <img className="Bday-giftIcon" src={giftIconGray} />
            <img className="Bday-giftIcon" src={giftIconPeach} />
            <img className="Bday-giftIcon" src={giftIconLime} />
            <img className="Bday-giftIcon" src={giftIconYellow} />
            <img className="Bday-giftIcon" src={giftIconBlue} />
            <img className="Bday-giftIcon" src={giftIcon} />
          </div>
        </button>
      </div>
    );

    return <div className="Bday-gifts">
      {gift1}
      {gift2}
    </div>
  }

  if (!isIn) {
    return (
      <div className={`Bday ${showGifts ? 'Bday--gifts' : 'Bday--main'}`}>
        <div className="Bday-passWrap">
          <input className="Bday-pass" type="text" value={pass ? pass : ''}
                onChange={(e) => setPass(e.target.value)}
                onBlur={(e) => {}}>
          </input>
          <div className="Bday-passPrompt">password</div>
        </div>
      </div>
    );
  }

  return (
    <div className={`Bday ${showGifts ? 'Bday--gifts' : 'Bday--main'}`}>
      <div className="Bday-container Container">
        <h1 className="Bday-heading">
          happy birthday mol!
        </h1>

        {showGifts ? renderGifts() :renderMain()}
      </div>
    </div>
  );
}
